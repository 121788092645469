
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ApexCharts from "apexcharts";
import { overviewService } from "@/services/api/overview.service";
import { NumTransfersByYear } from "@/models/entities/num-transfers-by-year.interface";

@Component
export default class StatChart extends Vue {
  @Prop({ required: false, type: String, default: "300px" })
  private height: string;

  @Prop({ required: true, type: Number, default: 0 })
  private year: number;

  private search = "";
  private chart: ApexCharts = null;
  private loading = false;
  private labels: Array<string> = [];
  private colors: Array<string> = [];
  private itemList: Array<NumTransfersByYear> = null;
  private currentHeight = -1;
  private months: string[] = [
    "Gen",
    "Feb",
    "Mar",
    "Apr",
    "Mag",
    "Giu",
    "Lug",
    "Ago",
    "Set",
    "Ott",
    "Nov",
    "Dic",
  ];

  async mounted(): Promise<void> {
    this.itemList = (await overviewService.getNumTransfers(this.year)).data;
    await this.getData(false);
  }

  public async getData(reload = true): Promise<void> {
    if (reload) {
      this.loading = true;
      this.itemList = [];
      this.loading = false;
      this.itemList = (await overviewService.getNumTransfers(this.year)).data;
    }
    this.buildChart();
  }

  async updated(): Promise<void> {
    if (this.$refs.chartDiv != undefined) {
      if (this.chart == null) {
        this.buildChart();
      }
    }
  }

  @Watch("year")
  private reloadYear() {
    this.getData();
  }

  private buildChart(): void {
    this.$nextTick(() => {
      if (this.itemList) {
        if (this.$refs.chartDiv == undefined) {
          //aggiorno perchè il canvas non è ancora stato creato
          this.$forceUpdate();
        } else {
          const data = {
            beneficiariesCount: [],
            // visits: [],
          };
          const labels = [];
          this.itemList.forEach((x) => {
            data.beneficiariesCount.push(x.beneficiariesCount);
          });
          this.months.forEach((x) => {
            labels.push(x);
          });
          const chartList = this.itemList;

          const options = {
            series: [
              {
                data: data.beneficiariesCount,
                name: "Bonifici",
              },
              // {
              //   data: data.visits,
              //   name: this.$tc("eres"),
              // },
            ],
            chart: {
              type: "bar",
              //   events: {
              //     click: function (event, chartContext, config) {
              //       if (config.seriesIndex != -1 && config.dataPointIndex != -1) {
              //         chartAction(
              //           chartList[config.dataPointIndex].promotionTypeID
              //         );
              //       }
              //     },
              //   },
              height: this.height,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                borderRadius: 8,
                horizontal: false,
              },
            },
            colors: ["#4954BA"],
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "14px",
              },
              colors: ["#4954BA"],
            },
            stroke: {
              width: 1,
            },
            xaxis: {
              categories: labels,
            },
          };
          if (this.chart != null) {
            this.chart.destroy();
          }
          this.chart = new ApexCharts(
            this.$refs.chartDiv as HTMLElement,
            options
          );
          this.chart.render();
        }
      }
    });
  }
}
